<template>
  <UiEmptyState
    :image-src="require('@/assets/img/something_went_wrong_error.svg')"
    :title-text="$t('Sorry, this order can not be returned.')"
  >
    <template #description>
      <p>{{ $t('Please check our return policy for more information.') }}</p>
    </template>
    <template
      v-if="allUrls"
      #actions
    >
      <UiButton
        mode="primary"
        class="main-button"
      >
        <a
          class="main-button__btn"
          :href="allUrls"
          :style="brandStyle"
        >{{ $t('Return policy') }}</a>
      </UiButton>
    </template>
  </UiEmptyState>
</template>
<script>
import { brandColourMixin, allUrls } from '@/components/mixins'
import { UiButton, UiEmptyState } from '@sendcloud/ui-library'

export default {
  name: 'NotAllowedError',
  components: {
    UiEmptyState,
    UiButton,
  },
  mixins: [brandColourMixin, allUrls],
}
</script>
